import clsx from "clsx";
import type { ReactNode } from "react";
type SliderProps = {
  readonly children: ReactNode;
  readonly activeStep?: number;
};
export function Slider({
  activeStep = 0,
  children
}: SliderProps) {
  return <div className="overflow-x-hidden" data-sentry-component="Slider" data-sentry-source-file="Slider.component.tsx">
      <ul className={clsx("flex w-full flex-nowrap justify-stretch transition duration-500 ease-in-out sm:duration-700")}
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- this is ok
    style={{
      transform: `translateX(-${activeStep * 100}%)`
    }}>
        {children}
      </ul>
    </div>;
}